/* eslint-disable max-len */
/* eslint-disable  react/require-default-props */
import React from "react";
import { Container as ReactContainer } from "react-bootstrap";

import mapModifiers from "utils/functions";

interface ContainerProps {
  fullscreen?: boolean;
  fixFullscreenSP?: any;
  fullPc?: any;
}

const ContainerLanding: React.FC<ContainerProps> = ({ children, fullscreen, fixFullscreenSP, fullPc }) => (
  <ReactContainer
    className={mapModifiers("o-container-landing", fullscreen && "fullscreen", fixFullscreenSP && "reset-margin", fullPc && "full-pc")}
  >
    {children}
  </ReactContainer>
);

export default ContainerLanding;
