/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import AvatarDefault from "assets/images/Avatar.svg";

export const getImageUrl = (url: string): string => {
  const patt = /^http/;
  // eslint-disable-next-line no-nested-ternary
  // eslint-disable-next-line max-len
  return url ? (patt.test(url) ? url : process.env.REACT_APP_BASE_URL_BACKEND + url) : AvatarDefault;
};
