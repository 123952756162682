export const STATUS_BTNADD = "STATUS_BTNADD";
export const STATUS_BTNCLOSE = "STATUS_BTNCLOSE";
export const STATUS_BTNPOPUP = "STATUS_BTNPOPUP";

export interface btnAddRequest {
  type: typeof STATUS_BTNADD;
}

export interface btnCloseRequest {
  type: typeof STATUS_BTNCLOSE;
}

export interface btnPopupRequest {
  type: typeof STATUS_BTNPOPUP;
}

export type TopListActionTypes = btnAddRequest | btnCloseRequest | btnPopupRequest;
