import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import ContainerLanding from "../ContainerLanding";

import imgNotify from "assets/images/landing/image_notify.png";
import Logo from "assets/images/logo.svg";
import Button from "components/atoms/Button";
import { Icon, IconButton } from "components/atoms/Icon";
import Image from "components/atoms/Image";
import Text from "components/atoms/Text";
import { getAccessToken } from "services/common/storage";

interface FooterProps { }

const FooterLanding: React.FC<FooterProps> = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const tokenState = getAccessToken();

  const handleActionFollow = () => {
    if (tokenState) {
      history.push("/follow");
    } else {
      setShow(true);
    }
  };

  const handleActionSetting = () => {
    if (tokenState) {
      history.push("/setting-account");
    } else {
      setShow(true);
    }
  };

  return (
    <footer className="o-footer-landing">
      <ContainerLanding>
        <div className="o-footer-landing_summary">
          <div className="o-footer-landing_summary_flex">
            <div className="o-footer-landing_summary_col">
              <Link to="/top-list">
                <div className="o-footer-landing_summary_logo" onClick={() => history.push("/top-list")}>
                  <Image ratio="logo" alt="logo_footer" src={Logo} />
                </div>
              </Link>
            </div>
            <div className="o-footer-landing_summary_col">
              <Text modifiers={["16-18", "richBlack", "500", "TT-Commons"]}>Menu</Text>
              <ul className="o-footer-landing_summary_list">
                <li className="o-footer-landing_summary_menu">
                  <div className="handle-follow" onClick={handleActionFollow}>
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      フォロー / フォロワー
                    </Text>
                  </div>
                </li>
                <li className="o-footer-landing_summary_menu">
                  <div className="handle-setting" onClick={handleActionSetting}>
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      アカウント設定
                    </Text>
                  </div>
                </li>
              </ul>
            </div>
            <div className="o-footer-landing_summary_col">
              <Text modifiers={["16-18", "richBlack", "500", "TT-Commons"]}>Company</Text>
              <ul className="o-footer-landing_summary_list">
                <li className="o-footer-landing_summary_menu">
                  <Link to="/terms-of-use">
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      利用規約
                    </Text>
                  </Link>
                </li>
                <li className="o-footer-landing_summary_menu">
                  <Link to="/privacy-policy">
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      プライバシーポリシー
                    </Text>
                  </Link>
                </li>
                <li className="o-footer-landing_summary_menu">
                  <a href="https://emole.co.jp/" target="_blank" rel="noreferrer">
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      運営会社
                    </Text>
                  </a>
                </li>
                <li className="o-footer-landing_summary_menu">
                  <a href="https://emole.co.jp/emole-contact" target="_blank" rel="noreferrer">
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      ご意見/お問い合わせ
                    </Text>
                  </a>
                </li>
              </ul>
            </div>
            <div className="o-footer-landing_summary_col">
              <Text modifiers={["16-18", "richBlack", "500", "TT-Commons"]}>SNS</Text>
              <ul className="o-footer-landing_summary_list">
                <li className="o-footer-landing_summary_menu">
                  <a href="https://twitter.com/emoleStudio" target="_blank" rel="noreferrer">
                    <Icon iconName="btwitter" />
                    <Text modifiers={["500", "richBlack2", "10-14"]}>Twitter</Text>
                  </a>
                </li>
                <li className="o-footer-landing_summary_menu">
                  <a
                    href="https://instagram.com/emole_studio/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon iconName="binsta" />
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      Instagram
                    </Text>
                  </a>
                </li>
                <li className="o-footer-landing_summary_menu">
                  <a href="https://tiktok.com/@in_the_emole?lang=ja-JP" target="_blank" rel="noreferrer">
                    <Icon iconName="btiktok" />
                    <Text modifiers={["500", "richBlack2", "10-14"]}>TikTok</Text>
                  </a>
                </li>
                <li className="o-footer-landing_summary_menu">
                  <a href="https://www.youtube.com/channel/UCDcBT1mhfPOtW3Obfu42pGg" target="_blank" rel="noreferrer">
                    <Icon iconName="byoutube" />
                    <Text modifiers={["500", "richBlack2", "10-14"]}>Youtube</Text>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="o-footer-landing_copyright">
          <div className="o-footer-landing_copyright_left">
            <Text modifiers={["richBlack", "10-11", "TT-Commons"]}>
              ©️2021 emole Inc All Rights Reserved.
            </Text>
          </div>
          <Text modifiers={["center", "10-14", "richBlack2"]}>
            好きで生きれる世界へ
          </Text>
        </div>
      </ContainerLanding>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="modal-notify"
        >
          <Modal.Header>
            <Modal.Title className="title-modal" id="example-custom-modal-styling-title">
              <div className="title-modal_icon">
                <IconButton iconName="closenotify" handleClick={() => setShow(false)} />
              </div>
              <div className="title-modal_title">
                会員登録をすると
                <br />
                この機能が使えます
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="body-modal">
            <img src={imgNotify} alt="img-notify" />
            <div className="body-modal_content">
              新規会員登録をするとこの機能が利用できます。
            </div>
            <div className="body-modal_button">
              <Button
                type="button"
                variant="danger"
                handleClick={() => history.push("/register")}
              >
                新規会員登録
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-custom_footer">
            <Button
              type="button"
              variant="primary"
              modifiers={["text", "sm"]}
              handleClick={() => history.push("/login")}
            >
              ログイン
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </footer>
  );
};
export default FooterLanding;
