/* eslint-disable  react/require-default-props */
/* eslint-disable  react/no-unused-prop-types */
import React from "react";

import Text from "../Text";

import mapModifiers from "utils/functions";

const iconList = {
  bell: "bell",
  menu: "menu",
  binsta: "binsta",
  winsta: "winsta",
  btiktok: "btiktok",
  wtiktok: "wtiktok",
  btwitter: "btwitter",
  wtwitter: "wtwitter",
  byoutube: "byoutube",
  wyoutube: "wyoutube",
  wgoogle: "wgoogle",
  bfacebook: "bfacebook",
  wfacebook: "wfacebook",
  back: "back",
  backphone: "back_phone",
  camera: "camera",
  finsta: "finsta",
  ftiktok: "ftiktok",
  ftwitter: "ftwitter",
  fyoutube: "fyoutube",
  success: "success",
  error: "error",
  arrowleft: "arrowleft",
  arrowright: "arrowright",
  upload: "upload",
  primaryArrowLeft: "primaryArrowLeft",
  union: "union",
  close: "close",
  bline: "bline",
  bnote: "bnote",
  bpinterest: "bpinterest",
  cameracreate: "camera-create",
  star: "star",
  brush: "brush",
  closeheader: "close-header",
  naviemail: "naviemail",
  logout: "logout",
  setting: "setting",
  speakeroff: "speakeroff",
  pen: "pen",
  backPhoneWhite: "backPhoneWhite",
  backPhoneYellow: "backPhoneYellow",
  share: "share",
  qr: "qr",
  search: "search",
  closenotify: "closenotify",
  trash: "trash",
};

export type IconName = keyof typeof iconList;

interface IconProps {
  iconName: IconName;
}

interface IconButtonProps extends IconProps {
  type?: "button" | "submit" | "reset";
  handleClick?: () => void;
  children?: any;
}

export const Icon: React.FC<IconProps> = ({ iconName }) => (
  <div className={mapModifiers("a-icon", iconName)} />
);

export const IconButton: React.FC<IconButtonProps> = ({
  iconName,
  type = "button",
  handleClick,
  children,
}) => (
  <button className="a-iconButton" type={type} onClick={handleClick}>
    <Icon iconName={iconName} />
    {
      children && (
        <Text modifiers={["12-17", "500", "brightCerulean"]}>{children}</Text>
      )
    }
  </button>
);
