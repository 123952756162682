let accessToken = window.localStorage.getItem("accessToken");
let refreshToken = window.localStorage.getItem("refreshToken");
let tokenType = window.localStorage.getItem("tokenType");
let careers = window.localStorage.getItem("careers");
/**
 * Listen for changes from other tabs
 */
window.addEventListener("storage", event => {
  if (event.key === "accessToken") {
    accessToken = event.newValue;
  }
});

export const getAccessToken = (): string | null => accessToken;

export const setAccessToken = (token: string): void => {
  accessToken = token;
  window.localStorage.setItem("accessToken", token);
};
export const setCareers = (arrayCareer: any): void => {
  careers = arrayCareer;
  if (arrayCareer.length !== 0) {
    window.localStorage.setItem("careers", JSON.stringify(arrayCareer));
  } else {
    window.localStorage.removeItem("careers");
  }
};
export const getCareers = (): any | null => careers;
export const getRefreshToken = (): string | null => refreshToken;

export const setRefreshToken = (token: string): void => {
  refreshToken = token;
  window.localStorage.setItem("refreshToken", token);
};

export const removeAccessToken = (): void => {
  accessToken = null;
  window.localStorage.removeItem("accessToken");
};

export const removeCareer = (): void => {
  careers = null;
  window.localStorage.removeItem("careers");
};

export const getTokenType = (): string | null => tokenType;

export const setTokenType = (type: string): void => {
  tokenType = type;
  window.localStorage.setItem("tokenType", type);
};

export const removeTokenType = (): void => {
  tokenType = null;
  window.localStorage.removeItem("tokenType");
};

export const setParentPage = (): void => {
  const currentUrl = window.location.href;
  localStorage.setItem("page", currentUrl);
};
export const getParentPage = (): string | null => localStorage.getItem("page");

export const removeParentPage = (): void => {
  localStorage.removeItem("page");
};

export const setNickName = (nickName: string): void => {
  localStorage.setItem("nickName", nickName);
};

export const getNickName = (): string | null => localStorage.getItem("nickName");

export const clearNickName = (): void => {
  localStorage.removeItem("nickName");
};

export const setOnceTopListPopup = (status: any): void => {
  localStorage.setItem("topListPopup", status);
};

export const removeOnceTopListPopup = (): void => {
  localStorage.removeItem("topListPopup");
};

export const getOnceTopListPopup = (): string | null => localStorage.getItem("topListPopup");
