import { useState, useLayoutEffect, useCallback } from "react";

const useScrollHeader = (): ("" | "show" | "hide") => {
  const [status, setStatus] = useState<"" | "show" | "hide">("");
  let currentPosition = 0;
  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 100) {
      if (window.pageYOffset < currentPosition) {
        setStatus("show");
      } else {
        setStatus("hide");
      }
    } else {
      setStatus("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    currentPosition = window.pageYOffset;
  }, []);
  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  return status;
};

export default useScrollHeader;
