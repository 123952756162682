import { combineReducers } from "redux";

import auth from "./auth";
import modal from "./modal";
import notify from "./notify";
import sample from "./sample";
import toplist from "./toplist";

export default combineReducers({
  sample,
  notify,
  auth,
  toplist,
  modal,
});
