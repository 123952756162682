/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Place from "assets/images/slide_place.png";
import Time from "assets/images/slide_time.png";
import Button from "components/atoms/Button";
import MainLayout from "components/templates/MainLayout";
import { getImageUrl } from "services/image/image";
import { formatDateJapan } from "utils/formatDate";

const DetailConfirmJob:React.FC = () => {
  const location = useLocation<any>();
  const history = useHistory();
  const { id } = useParams<any>();
  const [detailWork, setDetailWork] = useState<any>({});
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const scroll = useRef(null);
  const executeScroll = () => scroll?.current?.scrollIntoView();
  useEffect(() => {
    executeScroll();
    setDetailWork(location?.state?.detailWork);
    setEmail(location?.state?.email);
    setName(location?.state?.name);
  }, [location]);
  return (
    <MainLayout>
      <>
        <div className="p-detail_wrapper">
          <img ref={scroll} className="p-detail_wrapper-image" src={getImageUrl(detailWork?.image)} alt="Detail" />
          <div className="p-detail_wrapper-center">
            <div className="tag-container">
              {
              detailWork?.job?.split(" ").map((itemJob: string, index: number) => {
                return (
                  <span className="tag" key={index.toString()}>{itemJob}</span>
                );
              })
            }
            </div>
            <h1 className="p-detail_wrapper-center_title">{detailWork?.title}</h1>
            <div className="p-detail_wrapper-center_place">
              <img src={Place} alt="place" />
              <p>{detailWork?.place}</p>
            </div>
            <div className="p-detail_wrapper-center_time">
              <img src={Time} alt="time" />
              {formatDateJapan(detailWork?.date, true)}
              {" "}
              23:59までの募集
            </div>
            <div className="p-detail_wrapper-center_price">
              <div className="price">
                報酬
                <span>{detailWork?.salary}</span>
                円
              </div>
            </div>

            <div className="p-detail_wrapper-confirm">
              {name}
              {" "}
              さんのメールアドレス
              <br />
              （
              {email}
              ）宛に応募完了メールを送りましたので、確認をお願いします。
              <br />
              今後の流れは、応募完了メールからご確認ください。
              <br />
              迷惑メールフォルダに振り分けられることがあるため、通常のメールフォルダにない場合はそちらもご確認ください。
              <br />
              メールが届かない場合、
              <a href="mailto:support@emole.jp">support@emole.jp</a>
              までにお問い合わせください。
            </div>
            <div className="p-detail_wrapper-button">
              <Button
                type="button"
                variant="danger-gradient"
                modifiers={["filled", "sm"]}
                handleClick={() => history.push(`/detail-job/${id}`)}
              >
                応募内容を確認する
              </Button>
            </div>
          </div>
        </div>
      </>
    </MainLayout>
  );
};

export default DetailConfirmJob;
