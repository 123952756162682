import { NotifyActionTypes, SHOW_TOAST } from "./types";

interface HomeState {
  toast?: object,
}

const initialState: HomeState = {
  toast: {},
};

const NotifyReducer = (state = initialState, action: NotifyActionTypes): HomeState => {
  switch (action.type) {
    case SHOW_TOAST:
      return { toast: action };
    default:
      return state;
  }
};

export default NotifyReducer;
