/* eslint-disable array-callback-return */
function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === "string" ? [m] : m)]),

      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export const capitalizeText = (str?: string): string | undefined => {
  if (!str) return undefined;
  return str.charAt(0) + str.slice(1);
};

export const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = error => {
    reject(error);
  };
});

export default mapModifiers;

export const homeTo = (path: string) => {
  const before = window.location.href.split("//")[0];
  const after = window.location.href.split("//")[1];
  return `${before}//${after.split("/")[0]}/${path}`;
};

export const isCheckMarked = (data: any) => {
  let isEmpty = true;
  data?.map((list: any) => {
    if (list.key === "job") {
      list?.list.map((value: any) => {
        if (value.is_checked) {
          isEmpty = false;
        }
      });
    }
  });

  return isEmpty;
};

export const getFileExtension = (filename: any) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? "" : ext[1];
};

export const isCheckEmptyActivityContent = (data: any) => {
  let isEmpty = true;
  data?.map((list: any) => {
    if (list.isSelected) {
      isEmpty = false;
    }
  });
  return isEmpty;
};

export const isCheckMarkedDetail = (data: any) => {
  let isEmptyDetail = true;
  data?.map((list: any) => {
    if (list.is_checked) {
      isEmptyDetail = false;
    }
  });
  return isEmptyDetail;
};

export const getUserNameTiktok = (url: any) => {
  if (url) {
    return url.split("@").pop().split("?")[0];
  }
  return "";
};

export const getUserNameInstagram = (url: any) => {
  if (url) {
    const regex = /^$|http(?:s)?:\/\/(?:www\.)?instagram\.com\/([a-zA-Z0-9_@]+)/;
    const result = url.match(regex);
    if (result && result.length > 0) {
      return result[1];
    }
  }
  return "";
};

export const getUserNameFaceBook = (url: any) => {
  if (url) {
    const regex = /^$|http(?:s)?:\/\/(?:www\.)?facebook\.com\/([a-zA-Z0-9_@]+)/;
    const result = url.match(regex);
    if (result && result.length > 0) {
      return result[1];
    }
  }
  return "";
};

export const getUserNameNote = (url: any) => {
  if (url) {
    const regex = /^$|http(?:s)?:\/\/(?:www\.)?note\.jp|com\/([a-zA-Z0-9_@]+)/;
    const result = url.match(regex);
    if (result && result.length > 0) {
      return result[1];
    }
  }
  return "";
};

export const getUserNameLine = (url: any) => {
  if (url) {
    const regex = /^$|http(?:s)?:\/\/(?:www\.)?line\.me|jp|com\/([a-zA-Z0-9_@]+)/;
    const result = url.match(regex);
    if (result && result.length > 0) {
      return result[1];
    }
  }
  return "";
};

export const getUserNameTwitter = (url: any) => {
  if (url) {
    const regex = /^$|http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_@]+)/;
    const result = url.match(regex);
    if (result && result.length > 0) {
      return result[1];
    }
  }
  return "";
};

export const getUserNamePinterest = (url: any) => {
  if (url) {
    const regex = /^$|http(?:s)?:\/\/(?:www\.)?pinterest\.(jp|com)\/([a-zA-Z0-9_@]+)/;
    const result = url.match(regex);
    if (result && result.length > 0) {
      return result[2];
    }
  }
  return "";
};

export const getUserNameYoutube = (url: any) => {
  if (url) {
    const regex = /^$|http(?:s)?:\/\/(?:www\.)?youtube\.com\/(channel|c)\/([a-zA-Z0-9_@]+)/;
    const result = url.match(regex);
    if (result && result.length > 0) {
      return result[2];
    }
  }
  return "";
};

export const checkTypeFile = (file: any) => {
  const ext = /^.+\.([^.]+)$/.exec(file);
  return ext == null ? "" : ext[1];
};

export const replaceFlagImageGoogle = (imageUrl: any) => {
  if (typeof (imageUrl) !== "undefined" && imageUrl?.length > 0) {
    return imageUrl.replace("s96-c", "s384-c", true);
  }
  return "";
};

export const ListMessage = {
  register: {
    email_valid: "このメールアドレスは利用できません。別のメールアドレスを入力してください。",
    email_required: "メールアドレスを入力してください。",
    password_required: "パスワードを入力してください。",
    success: "基本情報の登録が完了しました。",
    fail: "基本情報の登録ができませんでした。",
    email_exists: "メールはすでに取られています",
    google_error: "Googleアカウントはすでにシステムに存在します",
    facebook_error: "Facebookアカウントはすでにシステムに存在します",
  },
  step1: {
    name_min: "名前は3文字以上必要です。",
    name_max: "名前は15文字以内で入力してください。",
    name_required: "パスワードを入力してください。",
    name_contain_alphanumeric: "名前には英数字のみを含める必要があります",
    name_not_space: "スペースは入力できません。",
    success: "基本情報の登録が完了しました。",
    fail: "基本情報の登録ができませんでした。",
  },
  step2: {
    field_required: "この項目は必須項目です。",
    success: "基本情報の登録が完了しました。",
    fail: "基本情報の登録ができませんでした。",
    number: "番号を指定する必要があります",
  },
  step3: {
    success: "基本情報の登録が完了しました。",
    fail: "基本情報の登録ができませんでした。",
  },
  step4: {
    twitter: "正しいtwitterアカウントのURLをご入力してください。",
    instagram: "正しいinstagramアカウントのURLをご入力してください。",
    youtube: "正しいyoutubeアカウントのURLをご入力してください。",
    tiktok: "正しいtiktokアカウントのURLをご入力してください。",
    facebook: "正しいfacebookアカウントのURLをご入力してください。",
    line: "正しいlineアカウントのURLをご入力してください。",
    note: "正しいnoteアカウントのURLをご入力してください。",
    pinterest: "正しいpinterestアカウントのURLをご入力してください。",
    success: "基本情報の登録が完了しました。",
    fail: "基本情報の登録ができませんでした。",
  },
  step5: {
    only: "160字以内で入力してください。",
    success: "基本情報の登録が完了しました。",
    fail: "基本情報の登録ができませんでした。",
  },
  step6: {
    success: "基本情報の登録が完了しました。",
    fail: "基本情報の登録ができませんでした。",
  },
  step7: {
    require: "カテゴリーと仕事内容を選択してください。",
    update_success: "カテゴリーを更新しました。",
    update_fail: "カテゴリーを更新できませんでした。",
    craete_success: "カテゴリーの登録が完了しました。",
    create_fail: "カテゴリーの登録ができませんでした。",
  },
  step8: {
    update_success: "学歴/職歴を更新しました。",
    update_fail: "学歴/職歴を更新できませんでした。",
    craete_success: "学歴/職歴の登録が完了しました。",
    create_fail: "学歴/職歴の登録ができませんでした。",
    require: "この項目は必須項目です。",
    number: "番号を指定する必要があります",
  },
  step9: {
    work_required: "作品/活動タイトルを入力してください。",
    detail_required: "仕事内容を入力してください。",
    describe_required: "説明を入力してください。",
    image_required: "画像をアップロードしてください。",
    craete_success: "ポートフォリオの登録が完了しました。",
    create_fail: "ポートフォリオの登録ができませんでした。",
  },
  login: {
    email_required: "メールアドレスを入力してください。",
    passwork_required: "パスワードを入力してください。",
    email_valid: "このメールアドレスは利用できません。別のメールアドレスを入力してください。",
    email_dont_exists: "このメールアドレスは存在しません。",
  },
  forgot: {
    password_dont_match: "パスワードを正しく入力してください。",
    email_valid: "このメールアドレスは利用できません。別のメールアドレスを入力してください。",
  },
};
