import axiosInstance from "services/common/instance";
// import {
//   ResponseCareerList,
// } from "store/reducers/auth/types";

export const getListCareers = async (): Promise<any> => {
  const res = await axiosInstance.get("career/list");
  return res.data?.data;
};

export const saveUserImages = async (params: any): Promise<any> => {
  const res = await axiosInstance.post("user/image", params);
  return res.data?.status;
};

export const saveUserName = async (params: any): Promise<any> => {
  const res = await axiosInstance.put("user/account-name", params);
  return res.data?.status;
};

export const getUserFollow = async (path: "follow" | "follower", page: number): Promise<any> => {
  const res = await axiosInstance.get(`user/${path}?page=${page}`);
  const userData = res.data?.data?.data?.map((u: {
    id: any;
    gender: any;
    // eslint-disable-next-line camelcase
    given_name: any;
    title: any;
    profession: any;
    // eslint-disable-next-line camelcase
    self_introduction: any;
    avatar: any;
    birthday: any;
    portfolio:any;
    // eslint-disable-next-line camelcase
    user_name: any;
  }) => ({
    id: u.id,
    gender: u.gender,
    givenName: u.given_name,
    title: u.title,
    profession: u.profession,
    selfIntroduction: u.self_introduction,
    avatar: u.avatar,
    birthday: u.birthday,
    userName: u.user_name,
    portfolio: u.portfolio,
  }));
  return {
    data: userData,
    perPage: res.data?.data?.per_page,
    total: res.data?.data?.total,
    current_page: res.data?.data?.current_page,
    total_page: res.data?.data?.last_page,
  };
};

export const followUser = async (id: number, action: "FOLLOW" | "UNFOLLOW"): Promise<any> => {
  const res = await axiosInstance.post("user/follow", {
    target_id: id,
    status: action,
  });
  return res.data?.status;
};

export const setRead = async (id: any): Promise<any> => {
  const res = await axiosInstance.post("user/setRead", {
    id,
  });
  return res.data?.status;
};

export const getUserImages = async (): Promise<any> => {
  const res = await axiosInstance.get("user/image/");
  return res.data?.data;
};

export const saveBasicInformation = async (params: any): Promise<any> => {
  const res = await axiosInstance.put("user/basic-information", params);
  return res.data?.status;
};

export const saveNewEmail = async (params: any): Promise<any> => {
  const res = await axiosInstance.put("user/email", params);
  return res.data?.status;
};

export const saveNewPhone = async (params: any): Promise<any> => {
  const res = await axiosInstance.put("user/phone", params);
  return res.data?.status;
};

export const saveEmailNotification = async (params: any): Promise<any> => {
  const res = await axiosInstance.put("user/email-notification", params);
  return res.data?.status;
};

export const saveChangePassword = async (params: any): Promise<any> => {
  const res = await axiosInstance.put("user/change-password", params);
  return res.data?.status;
};

export const getListUserWithOrder = async (): Promise<any> => {
  const res = await axiosInstance.get("users/list-order");
  return res.data?.data;
};

export const getListCreator = async (): Promise<any> => {
  const res = await axiosInstance.get("user/list-creator");
  return res.data?.data;
};

export const getAllUsersPagination = async (
  currentPage?: number, limit?: number, keyword?: string, onlyPortfolio?: boolean = false,
): Promise<any> => {
  const res = await axiosInstance.get(`users/list?current_page=${currentPage}&limit=${limit}${!keyword ? "" : `&keyword=${keyword}`}&query=${onlyPortfolio}`);
  return res.data;
};

export const getInfoByUserName = async (params: any): Promise<any> => {
  const res = await axiosInstance.get(`user/search/${params}`);
  return res.data?.data;
};
