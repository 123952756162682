/* eslint-disable no-debugger */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable  react/require-default-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import Container from "../Container";

import Avatar from "assets/images/Avatar.svg";
import Empty from "assets/images/empty.png";
import Logo from "assets/images/logo.svg";
import Button from "components/atoms/Button";
import { Icon, IconButton } from "components/atoms/Icon";
import Image from "components/atoms/Image";
import Text from "components/atoms/Text";
import useScrollHeader from "hooks/useScrollHeader";
import { getNotifyUser } from "services/auth/login";
import { removeAccessToken } from "services/common/storage";
import { getImageUrl } from "services/image/image";
import { setRead } from "services/info/user";
import { setStatusbtnAdd, setStatusbtnClose } from "store/reducers/toplist/actions";
import mapModifiers from "utils/functions";

interface HeaderProps {
  value?: string,
  isLogin?: boolean,
}
const Header: React.FC<HeaderProps> = ({ value, isLogin }) => {
  const history = useHistory<any>();
  const status = useScrollHeader();
  const [isShow, setShowIcon] = useState(true);
  const [isShowNotify, setIsShowNotify] = useState(true);
  const [hiddenIcon, showHeaderIcon] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>();
  const notifyRef = useRef<any>(null);
  const contentNotifyRef = useRef<any>(null);
  const settingRef = useRef<any>(null);
  const contentSettingRef = useRef<any>(null);
  const settingMobileClose = useRef<any>(null);
  const NotifyMobileClose = useRef<any>(null);
  const dispatch = useDispatch();
  const [notify, setNotify] = useState<any>([]);
  const [unread, setUnread] = useState(0);
  const currentUrl = window.location.href;
  // const [checkLogin, setCheckLogin] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (notifyRef.current && !notifyRef?.current.contains(event.target)) {
      // out side icon bell notify
      setIsShowNotify(true);
    }
    if (contentNotifyRef.current && contentNotifyRef?.current.contains(event.target)) {
      // inside content notify
      setIsShowNotify(false);
    }
    if (settingRef.current && !settingRef?.current.contains(event.target)) {
      // out side icon bell setting
      setShowIcon(true);
    }
    if (contentSettingRef.current && contentSettingRef?.current.contains(event.target)) {
      // inside content setting
      setShowIcon(false);
    }
    if (settingMobileClose.current && settingMobileClose?.current.contains(event.target)) {
      // icon close setting version mobile
      setShowIcon(true);
    }
    if (NotifyMobileClose.current && NotifyMobileClose?.current.contains(event.target)) {
      // icon close setting version mobile
      setIsShowNotify(true);
    }
  };

  useEffect(() => {
    const listPageNotShowIcon = [
      "register-introduce", "register-information", "register-result-step-one", "register-profile", "register-username", "activity-content",
      "activity-content", "register-sns", "register-porfolio-step-1", "register-category", "register-porfolio-information", "register-achievement-history",
    ];
    if (listPageNotShowIcon.find(x => currentUrl.includes(x))) {
      showHeaderIcon(true);
    }
    const { body } = document;
    document.getElementById("top-list")?.classList.add("show");
    document.documentElement.style.getPropertyValue("--scroll-y");
    body.style.height = "";
    body.style.overflowY = "";
    const user = localStorage.getItem("user");
    if (user) {
      setCurrentUser(JSON.parse(user));
    }
  }, [currentUrl]);

  useEffect(() => {
    const urlDontCallApi = ["login", "register", "terms-of-use", "privacy-policy", "forgot-password", "new-password", "notify-forgot-password"];
    if (!urlDontCallApi.find(url => currentUrl.includes(url)) && isLogin) {
      const getNotify = async () => {
        const res = await getNotifyUser();
        if (res) {
          res?.listUnRead?.map((itemUnRead: any) => {
            itemUnRead.unRead = true;
          });
          const newListNotify = res?.listUnRead.concat(res?.listRead);
          setNotify(newListNotify);
          setUnread(res.countUnRead);
        }
      };
      getNotify();
    }
  }, [currentUrl, isLogin]);

  const actionReadNotify = async (id: any) => {
    const check = await setRead(id);
    if (check) {
      const res = await getNotifyUser();
      if (res) {
        res?.listUnRead?.map((itemUnRead: any) => {
          itemUnRead.unRead = true;
        });
        const newListNotify = res?.listUnRead.concat(res?.listRead);
        setNotify(newListNotify);
        setUnread(res.countUnRead);
      }
    }
  };

  const btnMenuClick = () => {
    setShowIcon(!isShow);
    const { body } = document;
    if (isShow) {
      dispatch(setStatusbtnAdd());
      document.getElementById("top-list")?.classList.add("show");
      document.documentElement.style.getPropertyValue("--scroll-y");
      // body.style.height = "100vh";
      // body.style.overflowY = "hidden";
    } else {
      dispatch(setStatusbtnClose());
      const scrollY = body.style.top;
      body.style.position = "";
      body.style.top = "";
      body.style.height = "";
      body.style.overflowY = "";
      // eslint-disable-next-line radix
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      document.getElementById("top-list")?.classList.remove("show");
    }
  };
  const btnMenuClickNotify = () => {
    setIsShowNotify(!isShowNotify);
    const { body } = document;
    if (isShowNotify) {
      if (unread > 0) {
        setUnread(0);
        // setRead();
      }
      dispatch(setStatusbtnAdd());
      document.getElementById("top-list")?.classList.add("show");
      document.documentElement.style.getPropertyValue("--scroll-y");
      // body.style.height = "100vh";
      // body.style.overflowY = "hidden";
    } else {
      dispatch(setStatusbtnClose());
      const scrollY = body.style.top;
      body.style.position = "";
      body.style.top = "";
      body.style.height = "";
      body.style.overflowY = "";
      // eslint-disable-next-line radix
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      document.getElementById("top-list")?.classList.remove("show");
    }
  };
  const handleBtnClick = () => {
    history.push("/register-porfolio-information", "addNewPortfolio");
  };
  const gotoFollow = () => {
    history.push("/follow");
  };
  const gotoSetting = () => {
    history.push("/setting-account");
  };

  const gotoTopContact = () => {
    window.location.replace("https://emole.co.jp/emole-contact");
  };

  const handleLogout = () => {
    removeAccessToken();
    history.push("/");
  };

  return (
    <header className={mapModifiers("o-header", status)}>
      <Container>
        <div className="o-header_wrapper">
          <div className="o-header_logo" onClick={() => history.push("/top-list")}>
            <Image src={Logo} alt="main_logo" ratio="logo" />
          </div>
          {
            isLogin ? (
              <div className="o-header_option" hidden={hiddenIcon}>
                <div className="o-header_edit">
                  <Button
                    variant="danger"
                    type="button"
                    handleClick={() => { handleBtnClick(); }}
                  >
                    <Icon iconName="pen" />
                    作品追加
                  </Button>
                </div>
                <div className="o-header_bell icon-action" style={{ display: isShowNotify ? "flex" : "none" }} ref={notifyRef}>
                  <IconButton iconName="bell" handleClick={btnMenuClickNotify} />
                  { unread > 0 && <span className="unread-number">{unread}</span>}
                </div>
                <div className="o-header_menu icon-action" style={{ display: !isShowNotify ? "flex" : "none" }}>
                  <IconButton iconName="closenotify" handleClick={btnMenuClickNotify} />
                </div>
                <div className="o-header_menu icon-action" style={{ display: isShow ? "flex" : "none" }} ref={settingRef}>
                  <IconButton iconName="menu" handleClick={btnMenuClick} />
                </div>
                <div className="o-header_menu icon-action" style={{ display: !isShow ? "flex" : "none" }}>
                  <IconButton iconName="closeheader" handleClick={btnMenuClick} />
                </div>
              </div>
            ) : (
              <div className="only-check o-headerlanding_option">
                <div className="o-headerlanding_edit">
                  <Link to="/login" className="links">ログイン</Link>
                  <Button
                    variant="danger"
                    type="button"
                    handleClick={() => history.push("/register")}
                  >
                    新規登録
                  </Button>
                </div>
              </div>
            )
          }
        </div>
      </Container>
      <div className={!value ? "hidden-process" : "o-process-cover"}>
        <div className="o-process" style={{ width: `${value}%` }} />
      </div>
      <div className="popup-container">
        <div className="popup-content container" style={{ display: !isShow ? "flex" : "none" }}>
          <div className="content" ref={contentSettingRef}>
            <div className="menu-close" ref={settingMobileClose}>
              <IconButton iconName="closeheader" handleClick={btnMenuClick} />
            </div>
            <div className="current-user">
              <img
                className="avatar"
                src={currentUser?.avatar ? getImageUrl(currentUser.avatar) : Avatar}
                alt="avatar"
                onClick={() => history.push(`/${currentUser?.userName}`)}
              />
              <div className="info">
                <div className="name">
                  <Text modifiers={["25-36", "700", "richBlack2"]}>{currentUser?.userName}</Text>
                </div>
                <div className="profile-edit" onClick={() => history.push("/edit-profile")}>
                  <Text modifiers={["13-19", "500", "richBlack2"]}>プロフィールを編集</Text>
                </div>
              </div>
            </div>
            <div className="list-item">
              <div className="item">
                <Icon iconName="speakeroff" />
                <Text modifiers={["16-18", "500", "richBlack2"]} handleClick={gotoFollow}>フォロー / フォロワー</Text>
              </div>
              <div className="item">
                <Icon iconName="setting" />
                <Text modifiers={["16-18", "500", "richBlack2"]} handleClick={gotoSetting}>アカウント設定</Text>
              </div>
              <div className="item">
                <Icon iconName="naviemail" />
                <Text modifiers={["16-18", "500", "richBlack2"]} handleClick={gotoTopContact}>ご意見/お問い合わせ</Text>
              </div>
              <div className="item" onClick={handleLogout}>
                <Icon iconName="logout" />
                <Text modifiers={["16-18", "500", "richBlack2"]}>ログアウト</Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="popup-container-notify">
        <div className="popup-content container" style={{ display: !isShowNotify ? "flex" : "none" }}>
          <div className="content-notify" ref={contentNotifyRef}>
            <div className="menu-close" ref={NotifyMobileClose}>
              <IconButton iconName="closeheader" handleClick={btnMenuClickNotify} />
            </div>
            <div className="content-title">
              <Text modifiers={["23-20", "700", "richBlack2"]}>お知らせ</Text>
            </div>
            <div className="content-list">
              {
                notify?.length > 0 ? (
                  notify?.map((item: any) => {
                    return (
                      <a key={item.id} className="content-link" href={item?.url} target="_blank" rel="noreferrer" onClick={() => item.unRead && actionReadNotify(item.id)}>
                        <div className={`content-list_item ${item.unRead && "danger"}`}>
                          <div className="content-list_item-item">
                            <Text modifiers={["16-23", "700", "black"]}>{item?.subject}</Text>
                          </div>
                          <div className="content-list_item-desc">
                            <Text modifiers={["14-20", "500", "black"]}>{item?.delivery_contents}</Text>
                          </div>
                        </div>
                      </a>
                    );
                  })
                ) : (
                  <div className="empty-block">
                    <div className="hr" />
                    <div className="empty-contain">
                      <div className="img-empty">
                        <img src={Empty} alt="empty.svg" />
                      </div>
                      <div className="title-empty">
                        <Text modifiers={["20-29", "700", "platinum"]}>まだお知らせがありません</Text>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
