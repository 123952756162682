/* eslint-disable  react/require-default-props */
/* eslint-disable  react/no-unused-prop-types */
import React from "react";

import mapModifiers from "utils/functions";

// TODO: Define more ratio later !!!
export type Ratio = "logo" | "logo-lg";

interface ImageProps {
  src: string;
  alt: string;
  ratio: Ratio;
  handleClick?: () => void;
}

const Image: React.FC<ImageProps> = ({ src, alt, ratio, handleClick }) => (
  <div
    className={mapModifiers("a-image", ratio)}
    style={{ backgroundImage: `url(${src})` }}
  >
    <img className="a-image_thumbnail" src={src} alt={alt} onClick={handleClick} />
  </div>
);

export default Image;
