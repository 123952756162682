/* eslint-disable prefer-object-spread */

import { SAVE_INFO_USER } from "./types";

interface HomeState {
  users?: object,
}
const initialState: HomeState = {
  users: {},
};

const AuthReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case SAVE_INFO_USER:
      return { users: action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
