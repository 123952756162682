import React, { lazy } from "react";

import DetailConfirmJob from "pages/DetailConfirmJob";

const TopList = lazy(() => import("pages/TopList"));
const ActivityContent = lazy(() => import("pages/ActivityContent"));
const RegisterUserName = lazy(() => import("pages/RegisterUserName"));
const RegisterProfile = lazy(() => import("pages/RegisterProfile"));
const RegisterCategory = lazy(() => import("pages/RegisterCategories"));
const RegisterInfomation = lazy(() => import("pages/RegisterInfomation"));
const RegisterIntroduce = lazy(() => import("pages/RegisterIntroduce"));
const RegisterFinishStep1 = lazy(() => import("pages/RegisterFinishStep1"));
const RegisterAchievementHistory = lazy(() => import("pages/RegisterAchievementHistory"));
const RegisterSNSCooperation = lazy(() => import("pages/RegisterSNSCooperation"));
const RegisterPorfolioInit = lazy(() => import("pages/RegisterPorfolio/PorfolioFirstStep"));
const RegesterPorfolioInformation = lazy(() => import("pages/RegisterPorfolio/RegisterPorfolioInformation"));
const RedirectLine = lazy(() => import("pages/RedirectLine"));
const SettingAccount = lazy(() => import("pages/SettingAccount"));
const Follow = lazy(() => import("pages/Follow"));
const DeleteAccount = lazy(() => import("pages/DeleteAccount"));
const SuggestMembers = lazy(() => import("pages/SuggestMembers"));
const EditProfile = lazy(() => import("pages/EditProfile"));
// const DetailJob = lazy(() => import("pages/DetailJob"));

type Route = {
  path: string;
  component: React.FC;
  exact: true;
};

const routes: Route[] = [
  {
    path: "/top-list",
    component: TopList,
    exact: true,
  },
  {
    path: "/activity-content",
    component: ActivityContent,
    exact: true,
  },
  {
    path: "/register-username",
    component: RegisterUserName,
    exact: true,
  },
  {
    path: "/register-profile",
    component: RegisterProfile,
    exact: true,
  },
  {
    path: "/register-category/:id",
    component: RegisterCategory,
    exact: true,
  },
  {
    path: "/register-information",
    component: RegisterInfomation,
    exact: true,
  },
  {
    path: "/register-introduce",
    component: RegisterIntroduce,
    exact: true,
  },
  {
    path: "/register-result-step-one",
    component: RegisterFinishStep1,
    exact: true,
  },
  {
    path: "/register-achievement-history",
    component: RegisterAchievementHistory,
    exact: true,
  },
  {
    path: "/register-sns",
    component: RegisterSNSCooperation,
    exact: true,
  },
  {
    path: "/register-porfolio-step-1",
    component: RegisterPorfolioInit,
    exact: true,
  },
  {
    path: "/register-porfolio-information",
    component: RegesterPorfolioInformation,
    exact: true,
  },
  {
    path: "/register-porfolio-information/:id",
    component: RegesterPorfolioInformation,
    exact: true,
  },
  {
    path: "/setting-account",
    component: SettingAccount,
    exact: true,
  },
  {
    path: "/line-offical",
    component: RedirectLine,
    exact: true,
  },
  {
    path: "/follow",
    component: Follow,
    exact: true,
  },
  {
    path: "/delete-account",
    component: DeleteAccount,
    exact: true,
  },
  {
    path: "/suggest-member",
    component: SuggestMembers,
    exact: true,
  },
  {
    path: "/edit-profile",
    component: EditProfile,
    exact: true,
  },
  // {
  //   path: "/detail-job/:id",
  //   component: DetailJob,
  //   exact: true,
  // },
  {
    path: "/confirm-job/:id",
    component: DetailConfirmJob,
    exact: true,
  },
];

export default routes;
