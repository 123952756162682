/* eslint-disable prefer-object-spread */

import { SHOW_MODAL_REQUIRE_AUTH } from "../auth/types";

const initialState: any = {
  isShow: false,
};

const ModalReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case SHOW_MODAL_REQUIRE_AUTH:
      return { isShow: action.payload };
    default:
      return state;
  }
};

export default ModalReducer;
