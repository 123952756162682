import { STATUS_BTNADD, STATUS_BTNCLOSE, STATUS_BTNPOPUP, TopListActionTypes } from "./types";

export const setStatusbtnAdd = (): TopListActionTypes => ({
  type: STATUS_BTNADD,
});

export const setStatusbtnClose = (): TopListActionTypes => ({
  type: STATUS_BTNCLOSE,
});

export const setStatusPopupNewUser = (): TopListActionTypes => ({
  type: STATUS_BTNPOPUP,
});
