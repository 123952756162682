import React from "react";
import { Link, useHistory } from "react-router-dom";

import ContainerLanding from "../ContainerLanding";

import Logo from "assets/images/logo.svg";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";
import useScrollHeader from "hooks/useScrollHeader";
import mapModifiers from "utils/functions";

const HeaderLanding: React.FC = () => {
  const history = useHistory();
  const status = useScrollHeader();

  return (
    <header className={mapModifiers("o-headerlanding", status)}>
      <ContainerLanding>
        <div className="o-headerlanding_wrapper">
          <div className="o-headerlanding_logo" onClick={() => history.push("/top-list")}>
            <Image src={Logo} alt="main_logo" ratio="logo" />
          </div>
          <div className="o-headerlanding_option">
            <div className="o-headerlanding_edit">
              <Link to="/login" className="links">ログイン</Link>
              <Button
                variant="danger"
                type="button"
                handleClick={() => history.push("/register")}
              >
                新規登録
              </Button>
            </div>
          </div>
        </div>
      </ContainerLanding>
    </header>
  );
};

export default HeaderLanding;
