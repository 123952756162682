/* eslint-disable  react/require-default-props */
import React, { useEffect, useState } from "react";

import { Icon } from "../Icon";

interface notifyProps {
  toast?:any
}

const Notify: React.FC<notifyProps> = ({ toast }) => {
  const [notify, setNotify] = useState(toast);
  useEffect(() => {
    setNotify(toast);
    const interval = setInterval(() => {
      setNotify({});
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, [toast]);

  return (
    <>
      { (notify && Object.keys(notify).length > 0) && (
        <div className={`a-notification ${notify[0]}`}>
          <div className="a-notification_image">
            {
              notify[1] === "success" ? (
                <Icon iconName="success" />
              ) : (
                <Icon iconName="error" />
              )
            }
          </div>
          <div>
            <p className="a-notification_message">
              {notify[2]}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default Notify;
