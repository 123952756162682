export const formatDateJapan = (date: any, all = false) => {
  if (!date) {
    return null;
  }
  const d = new Date(date);
  // eslint-disable-next-line no-restricted-globals
  if (d instanceof Date && !isNaN(d.valueOf())) {
    const lang = "en";
    const year = d.toLocaleString(lang, { year: "numeric" });
    const month = d.toLocaleString(lang, { month: "numeric" });
    const day = d.toLocaleString(lang, { day: "numeric" });
    if (all) {
      return (`${year}年 ${month}月 ${day}日`);
    }
    return (`${year}年 ${month}月`);
  }
  return false;
};

export const getYearMonth = (date: any) => {
  if (date !== null) {
    const d = new Date(date);
    // eslint-disable-next-line no-restricted-globals
    if (d instanceof Date && !isNaN(d.valueOf())) {
      const lang = "en";
      const year = d.toLocaleString(lang, { year: "numeric" });
      const month = d.toLocaleString(lang, { month: "numeric" });
      return (`${year}.${month}`);
    }
  }
  return false;
};

export const getCurrentAge = (date: any) => {
  // var ageDifMs = Date.now() - birthday.getTime();
  // var ageDate = new Date(ageDifMs); // miliseconds from epoch
  // return Math.abs(ageDate.getUTCFullYear() - 1970);
  if (date !== null) {
    const d = new Date(date);
    const today = new Date();
    // eslint-disable-next-line no-restricted-globals
    if (d instanceof Date && !isNaN(d.valueOf())) {
      const lang = "en";
      const yearNow = today.getFullYear();
      const year = d.toLocaleString(lang, { year: "numeric" });
      const age = yearNow - Number(year);
      return age > 0 ? age - 1 : 0;
    }
  }
  return false;
};
