import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import Container from "../Container";

import imgCreatePortfolio from "assets/images/landing/image_create_portfolio.png";
import imgNotify from "assets/images/landing/image_notify.png";
import Logo from "assets/images/logo.svg";
import Button from "components/atoms/Button";
import { Icon, IconButton } from "components/atoms/Icon";
import Image from "components/atoms/Image";
import Text from "components/atoms/Text";
import { getUserInfoService } from "services/auth/login";
import { getAccessToken } from "services/common/storage";
import { useAppSelector } from "store";
import { showModalRequireAuth } from "store/reducers/modal/action";
import { setStatusPopupNewUser } from "store/reducers/toplist/actions";

const Footer: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [showCreatePortfolio, setShowCreatePortfolio] = useState(false);
  const handleCloseCreate = () => setShowCreatePortfolio(false);
  const tokenState = getAccessToken();
  const { popupNewUser } = useAppSelector(state => state.toplist);
  const { isShow } = useAppSelector(state => state.modal);
  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    dispatch(showModalRequireAuth(false));
  };

  useEffect(() => {
    if (location?.state?.action) {
      setShowCreatePortfolio(true);
    }
  }, [location]);

  const handleActionFollow = () => {
    if (tokenState) {
      history.push("/follow");
    } else {
      setShow(true);
    }
  };
  useEffect(() => {
    const infoUser = async () => {
      const res = await getUserInfoService();
      if (res) {
        if (res?.status_popup) {
          dispatch(setStatusPopupNewUser());
        }
      }
    };
    infoUser();
  }, [dispatch]);

  const handleActionSetting = () => {
    if (tokenState) {
      history.push("/setting-account");
    } else {
      setShow(true);
    }
  };

  const actionCloseModal = useCallback(() => {
    setShowCreatePortfolio(false);
    history.replace({ ...location, state: undefined });
  }, [history, location]);

  const handleBtnClick = () => {
    history.push("/register-porfolio-information", "addNewPortfolio");
  };

  const handleClickRegister = () => {
    dispatch(showModalRequireAuth(false));
    history.push("/");
  };

  const handleClickLogin = () => {
    dispatch(showModalRequireAuth(false));
    history.push("/login");
  };
  return (
    <footer className="o-footer">
      <Container>
        <div className="o-footer_summary">
          <div className="o-footer_summary_flex">
            <div className="o-footer_summary_col">
              <Link to="/top-list">
                <div className="o-footer_summary_logo" onClick={() => history.push("/top-list")}>
                  <Image ratio="logo" alt="logo_footer" src={Logo} />
                </div>
              </Link>
            </div>
            <div className="o-footer_summary_col">
              <Text modifiers={["16-18", "richBlack", "500", "TT-Commons"]}>Menu</Text>
              <ul className="o-footer_summary_list">
                <li className="o-footer_summary_menu">
                  <div className="handle-follow" onClick={handleActionFollow}>
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      フォロー / フォロワー
                    </Text>
                  </div>
                </li>
                <li className="o-footer_summary_menu">
                  <div className="handle-setting" onClick={handleActionSetting}>
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      アカウント設定
                    </Text>
                  </div>
                </li>
              </ul>
            </div>
            <div className="o-footer_summary_col">
              <Text modifiers={["16-18", "richBlack", "500", "TT-Commons"]}>Company</Text>
              <ul className="o-footer_summary_list">
                <li className="o-footer_summary_menu">
                  <Link to="/terms-of-use">
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      利用規約
                    </Text>
                  </Link>
                </li>
                <li className="o-footer_summary_menu">
                  <Link to="/privacy-policy">
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      プライバシーポリシー
                    </Text>
                  </Link>
                </li>
                <li className="o-footer_summary_menu">
                  <a href="https://emole.co.jp/" target="_blank" rel="noreferrer">
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      運営会社
                    </Text>
                  </a>
                </li>
                <li className="o-footer_summary_menu">
                  <a href="https://emole.co.jp/emole-contact" target="_blank" rel="noreferrer">
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      ご意見/お問い合わせ
                    </Text>
                  </a>
                </li>
              </ul>
            </div>
            <div className="o-footer_summary_col">
              <Text modifiers={["16-18", "richBlack", "500", "TT-Commons"]}>SNS</Text>
              <ul className="o-footer_summary_list">
                <li className="o-footer_summary_menu">
                  <a href="https://twitter.com/emoleStudio" target="_blank" rel="noreferrer">
                    <Icon iconName="btwitter" />
                    <Text modifiers={["500", "richBlack2", "10-14"]}>Twitter</Text>
                  </a>
                </li>
                <li className="o-footer_summary_menu">
                  <a
                    href="https://instagram.com/emole_studio/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon iconName="binsta" />
                    <Text modifiers={["500", "richBlack2", "10-14"]}>
                      Instagram
                    </Text>
                  </a>
                </li>
                <li className="o-footer_summary_menu">
                  <a href="https://tiktok.com/@in_the_emole?lang=ja-JP" target="_blank" rel="noreferrer">
                    <Icon iconName="btiktok" />
                    <Text modifiers={["500", "richBlack2", "10-14"]}>TikTok</Text>
                  </a>
                </li>
                <li className="o-footer_summary_menu">
                  <a href="https://www.youtube.com/channel/UCDcBT1mhfPOtW3Obfu42pGg" target="_blank" rel="noreferrer">
                    <Icon iconName="byoutube" />
                    <Text modifiers={["500", "richBlack2", "10-14"]}>Youtube</Text>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="o-footer_copyright">
          <div className="o-footer_copyright_left">
            <Text modifiers={["richBlack", "10-11", "TT-Commons"]}>
              ©️2021 emole Inc All Rights Reserved.
            </Text>
          </div>
          <Text modifiers={["center", "10-14", "richBlack2"]}>
            好きで生きれる世界へ
          </Text>
        </div>
      </Container>
      <div>
        <Modal
          show={show || isShow}
          onHide={handleClose}
          centered
          className="modal-notify"
        >
          <Modal.Header>
            <Modal.Title className="title-modal" id="example-custom-modal-styling-title">
              <div className="title-modal_icon">
                <IconButton iconName="closenotify" handleClick={() => handleClose()} />
              </div>
              <div className="title-modal_title">
                会員登録をすると
                <br />
                この機能が使えます
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="body-modal">
            <img src={imgNotify} alt="img-notify" />
            <div className="body-modal_content">
              新規会員登録をするとこの機能が利用できます。
            </div>
            <div className="body-modal_button">
              <Button
                type="button"
                variant="danger"
                handleClick={() => handleClickRegister()}
              >
                新規会員登録
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-custom_footer">
            <Button
              type="button"
              variant="primary"
              modifiers={["text", "sm"]}
              handleClick={() => handleClickLogin()}
            >
              ログイン
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal
          show={showCreatePortfolio && !popupNewUser}
          onHide={handleCloseCreate}
          centered
          className="modal-notify"
        >
          <Modal.Header>
            <Modal.Title className="title-modal" id="example-custom-modal-styling-title">
              <div className="title-modal_icon">
                <IconButton iconName="closenotify" handleClick={() => { actionCloseModal(); }} />
              </div>
              <div className="title-modal_title">
                ポートフォリオの登録が
                <br />
                完了しました
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="body-modal">
            <div className="body-modal_img">
              <img src={imgCreatePortfolio} alt="img-notify" />
            </div>
            <div className="body-modal_content">
              登録お疲れ様でした。ポートフォリオを複数登録すると案件に応募した際に審査に通過しやすくなります。
            </div>
            <div className="body-modal_button">
              <Button
                type="button"
                variant="danger"
                handleClick={() => { handleBtnClick(); }}
              >
                ポートフォリオを追加
              </Button>
            </div>
            {
              location?.state?.action !== "dontExistsNewCareer" && (
                <>
                  <div className="body-modal_title">
                    新しく追加した活動内容
                  </div>
                  <div className="body-modal_career">
                    {`「${location?.state?.action}」`}
                  </div>
                  <div className="body-modal_description">
                    プロフィールの仕事内容に「モデル」を追加しました。
                    <br />
                    モデルの活動に関する詳細情報を登録するとより案件通過率が高くなります。
                  </div>
                  <div className="body-modal_button">
                    <Button
                      type="button"
                      variant="danger"
                      modifiers={["outlined"]}
                      handleClick={() => history.push(`register-category/${location?.state?.career?.value}?page=profile`, location?.state?.career?.label)}
                    >
                      活動内容を編集
                    </Button>
                  </div>
                </>
              )
            }
          </Modal.Body>
        </Modal>
      </div>
    </footer>
  );
};
export default Footer;
