import axiosInstance from "services/common/instance";
import {
  LoginParams,
  LoginResponse,
  UserResponseType,
} from "store/reducers/auth/types";

export const loginService = async (
  params: LoginParams,
): Promise<LoginResponse> => {
  const res = await axiosInstance.post("user/login", params);
  return res.data;
};

export const getUserInfoService = async (): Promise<UserResponseType> => {
  const res = await axiosInstance.get("user");
  return res.data.data;
};

export const getUserInfoLogin = async (): Promise<any> => {
  const res = await axiosInstance.get("user");
  return res.status;
};

export const updatePopupFirstLogin = async (): Promise<any> => {
  const res = await axiosInstance.put("user/update-popup");
  return res.status;
};

export const getNotifyUser = async (): Promise<any> => {
  const res = await axiosInstance.get("user/notify");
  return res.data.data;
};

export const loginGoogle = async (params: any): Promise<any> => {
  try {
    const res = await axiosInstance.post("user/login-google", params);
    return res.data;
  } catch (e) {
    return false;
  }
};
export const loginFacebook = async (params: any): Promise<any> => {
  try {
    const res = await axiosInstance.post("user/login-facebook", params);
    return res.data;
  } catch (e) {
    return false;
  }
};

export const getLoginTwitterInfo = async (): Promise<any> => {
  const res = await axiosInstance.get("twitter-login/get-info");
  return res.data;
};

export const loginTwitter = async (params: any): Promise<any> => {
  const res = await axiosInstance.post("twitter-login/login", params);
  return res.data;
};
