import { STATUS_BTNADD, STATUS_BTNCLOSE, STATUS_BTNPOPUP, TopListActionTypes } from "./types";

interface TopListState {
  status: any;
  popupNewUser: any;
}

const initialState: TopListState = {
  status: false,
  popupNewUser: true,
};

const topListReducer = (state = initialState, action: TopListActionTypes): any => {
  switch (action.type) {
    case STATUS_BTNADD:
      return { status: true };
    case STATUS_BTNCLOSE:
      return { status: false };
    case STATUS_BTNPOPUP:
      return { popupNewUser: false };
    default:
      return state;
  }
};

export default topListReducer;
