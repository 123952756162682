/* eslint-disable camelcase */
export const SAVE_INFO_USER = "SAVE_INFO_USER";
export const SHOW_MODAL_REQUIRE_AUTH = "SHOW_MODAL_REQUIRE_AUTH";
export type UserResponseType = {
  id: number;
  user_name: string;
  given_name?: string;
  email: string;
  email_verified_at?: string;
  title?: string;
  profession?: string;
  gender?: string;
  birthday?: string;
  self_introduction?: string;
  avatar?: string;
  created_at: string;
  update_at: string;
  register_finish_step: number;
  careers?: any;
  activity_base_id?: number;
  is_enable_email_notification?: number;
  status_popup?: any;
}

/* ---------------- */

export interface LoginParams {
  email: string;
  password: string;
}

export interface FormRegisterS2Params {
  user_name: string;
}

export interface FormRegisterS3Params {
  avatar?: string;
  given_name: string;
  title: string;
  email: string;
  gender: "MALE" | "FEMALE" | "OTHER";
  date?: string;
  month?: string;
  year?: string;
  profession: string;
  birthday?: string;
  activity_base_id: any;
}

/* ---------------- */
export interface FormForgotPassword {
  email: string;
}

export interface FormNewPassword {
  password: string;
  password_confirmation: string;
  token: string;
}
export interface FormChangePassword {
  exist_password: string;
  new_password: string;

}

/* ---------------- */
export interface LoginResponse {
  status: boolean;
  access_token: string;
  token_type: string;
}

export interface UserS2Response {
  status: string;
  user: UserResponseType
}

export interface ResponseOnlyStatus {
  status: boolean;
}

export interface ResponseOnlyToken {
  token: any;
}

/* ---------------- */

export interface ErrorResponse {
  status: boolean;
  message: string;
}

export interface ErrorRegisterS1Response {
  message: string;
  errors: Record<string, [string]>
}

export interface APISingleResponse<D> {
  data: D;
}

/* ---------------- */

export interface AuthState {
  user: UserResponseType | null;
  isLogin?: boolean;
  loading: boolean;
  errorLogin?: ErrorResponse | null;
  errorRegister?: ErrorRegisterS1Response | null;
}

/* ---------------- */

export interface ResponseCareerList {
  status: boolean;
  data: any;
}
