import axios from "axios";

import { getAccessToken, getTokenType } from "./storage";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  ($config) => {
    const token = getAccessToken();
    const tokenType = getTokenType();
    if (token && token.length) {
      $config.headers.Authorization = `${tokenType || "Bearer"} ${token}`;
    }
    $config.headers["Content-Type"] = "application/json";
    $config.headers.Accept = "application/json";
    return $config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
