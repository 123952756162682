/* eslint-disable react/jsx-indent */
/* eslint-disable import/first */
/* eslint-disable import/order */
/* eslint-disable  react/require-default-props */
import React, { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import Notify from "components/atoms/Notify";
import routes from "routes";
import store, { useAppSelector } from "store";
import "./App.scss";

const Login = lazy(() => import("pages/Login"));
const LandingPage = lazy(() => import("pages/LandingPage"));
const Register = lazy(() => import("pages/Register"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const DetailProfile = lazy(() => import("pages/DetailProfile"));
const NotifyForgotPassword = lazy(() => import("pages/NotifyForgotPassword"));
const NewPassword = lazy(() => import("pages/NewPassword"));
const NotifyActive = lazy(() => import("pages/NotifyActive"));
const ActiveAccount = lazy(() => import("pages/ActiveAccount"));
const TermOfService = lazy(() => import("pages/TermOfService"));
const PrivacyOfService = lazy(() => import("pages/PrivacyOfService"));
const NotFound = lazy(() => import("pages/NotFound"));
const LoginTwitterCallback = lazy(() => import("pages/Login/TwitterCallback"));
const DetailJob = lazy(() => import("pages/DetailJob"));
import PrivateRoute from "components/atoms/PrivateRoute";

const App: React.FC = () => {
  const { toast } = useAppSelector(state => state.notify);
  return (
    <Router>
      <Suspense fallback="">
        <Notify toast={toast} />
        <Switch>
          {routes.map(({ component: Component, ...rest }, index) => (
            <PrivateRoute
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest}
              key={index.toString()}
            >
              <Component />
            </PrivateRoute>
          ))}
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/not-found" component={NotFound} />
          <Route exact path="/terms-of-use" component={TermOfService} />
          <Route exact path="/privacy-policy" component={PrivacyOfService} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/login/twitter/callback" component={LoginTwitterCallback} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/notify-active" component={NotifyActive} />
          <Route exact path="/active/:token" component={ActiveAccount} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/new-password/:token" component={NewPassword} />
          <Route exact path="/notify-forgot-password" component={NotifyForgotPassword} />
          <Route exact path="/:userName" component={DetailProfile} />
          <Route exact path="/detail-job/:id" component={DetailJob} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};
const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default AppWrapper;
