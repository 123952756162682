/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-debugger */
/* eslint-disable  react/require-default-props */
import React, { useEffect } from "react";
import {
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";

import { getUserInfoLogin } from "services/auth/login";
import { getAccessToken, removeAccessToken } from "services/common/storage";

interface PrivateRouteProps extends RouteProps {
  token?: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  ...rest
}) => {
  const tokenCode = getAccessToken();
  useEffect(() => {
    if (tokenCode && tokenCode.length > 0) {
      const checkLogin = async () => {
        try {
          await getUserInfoLogin();
        } catch {
          removeAccessToken();
          window.location.reload();
        }
      };
      checkLogin();
    }
  }, [tokenCode]);
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props: any) => ((tokenCode) ? (
        children
      )
        : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        ))}
    />
  );
};

export default PrivateRoute;
