/* eslint-disable  react/require-default-props */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Footer from "components/organisms/Footer";
import FooterLanding from "components/organisms/FooterLanding";
import Header from "components/organisms/Header";
import HeaderLanding from "components/organisms/HeaderLanding";
import mapModifiers from "utils/functions";

interface MainLayoutProps {
  // TODO: reconfirm footer ( hide some page ) later !!
  isHideFooter?: boolean;
  isNarrow?: boolean;
  valueProcess?: string;
  mobileHidden?: any;
  isLogin?: boolean;
  landing?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  isHideFooter,
  children,
  valueProcess,
  isNarrow,
  mobileHidden = false,
  isLogin = true,
  landing = false,
}) => {
  const [isShowHeader, setHeader] = useState(true);
  // const [isDisableScroll, setDisableScroll] = useState(false);
  const location = useLocation<any>();
  useEffect(() => {
    const currentUrl = window.location.href;
    const listUrl = [
      "login",
    ];
    if (listUrl.find(x => currentUrl.includes(x))) {
      setHeader(false);
    }
  }, []);
  return (
    <div className={mapModifiers("t-mainLayout", isNarrow && "narrow")}>
      <div className={`t-mainLayout_head ${isShowHeader ? "show" : "hide"} ${mobileHidden && "hidden-header-mb"}`}>
        {
          location.pathname === "/" ? (<HeaderLanding />) : <Header isLogin={isLogin} value={valueProcess} />
        }
      </div>
      <div className={`t-mainLayout_body ${landing && "rm-margin"}`}>
        <div className="t-mainLayout_content">
          {children}
        </div>
      </div>
      {!isHideFooter && (
        <div className="t-mainLayout_foot">
          {
            location.pathname === "/" ? (<FooterLanding />) : <Footer />
          }
        </div>
      )}
    </div>
  );
};

export default MainLayout;
