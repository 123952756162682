/* eslint-disable  react/require-default-props */
/* eslint-disable  react/no-unused-prop-types */
import React from "react";

import { Icon, IconName } from "../Icon";

import mapModifiers from "utils/functions";

interface ButtonProps {
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  handleClick?: any;
  icon?: IconName;
  modifiers?: ("filled" | "outlined" | "text" | "lg" | "sm" | "bold")[];
  variant?: "primary" | "primary-light" | "danger" | "danger-light" | "black" | "platinum" | "bright-cerulean" | "green" | "platinum-light" | "danger-gradient" | "azure-Radiance";
  style?: any;
}

const Button: React.FC<ButtonProps> = ({
  disabled,
  handleClick,
  children,
  variant,
  icon,
  modifiers = ["filled"],
  type = "button",
  style,
}) => (
  <button
    onClick={handleClick}
    disabled={disabled}
    className={mapModifiers("a-button", modifiers, variant)}
    type={type}
    style={style}
  >
    {icon && <Icon iconName={icon} />}
    {children}
  </button>
);

export default Button;
