/* eslint-disable  react/require-default-props */
import React from "react";

import IconRequired from "assets/icons/ic-required.svg";
import mapModifiers from "utils/functions";

export type ColorType =
  | "black"
  | "white"
  | "lust"
  | "mikadoYellow"
  | "crayolaGreen"
  | "brightCerulean"
  | "violet"
  | "platinum"
  | "richBlack"
  | "richBlack2"
  | "richBlack3"
  | "taupeGray"
  | "blackRussian"
  | "gray900"
  | "titleGray";

export type StyleTextType =
  | "left"
  | "center"
  | "right"
  | "uppercase"
  | "capitalize"
  | "lowercase";

export type SizeTextType =
  | "10-11"
  | "10-14"
  | "10-22"
  | "12-17"
  | "13-19"
  | "14-20"
  | "15-22"
  | "18-26"
  | "15-28"
  | "16-18"
  | "16-23"
  | "20-29"
  | "23-20"
  | "22-32"
  | "25-36"
  | "28-32"
  | "30-43"
  | "108-124";

export type StyleFontWeight =
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900";

export type StyleFontFamily =
  | "TT-Commons"
  | "Noto-Sans-JP";

interface TextProps {
  modifiers?: (ColorType | StyleTextType | SizeTextType | StyleFontWeight | StyleFontFamily)[];
  handleClick?: any;
  required?: any;
  fixTextLagger?: any;
  fixNoWrap?: any;
}

const Text: React.FC<TextProps> = ({ modifiers, children, handleClick, required = false,
  fixNoWrap = false, fixTextLagger = false }) => (
    <p onClick={handleClick} className={mapModifiers("a-text", modifiers, `${fixTextLagger && "flex"}`, `${fixNoWrap && "nowrap"}`)}>
      {children}
      {
        required && (
          <img className="img-required" src={IconRequired} alt="text-required" />
        )
      }
    </p>
);

export default Text;
